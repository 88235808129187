import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Collection from 'components/Collection';

const CollectionAllPage = props => {
  let {
    data: {
      allShopifyProduct: { nodes: products },
      shopifyCollection: { products: colorProducts },
    },
  } = props;

  let colorHandles = colorProducts.map(p => p.handle);
  let noColorProducts = products.filter(p => !colorHandles.includes(p.handle));

  return (
    <Layout>
      <Collection all products={noColorProducts} title={'All products'} />
    </Layout>
  );
};

export default CollectionAllPage;

export const query = graphql`
  query {
    allShopifyProduct {
      nodes {
        ...ShopifyProductFields
      }
    }
    shopifyCollection(handle: { eq: "kenra-color-all" }) {
      ...shopifyCollectionFields
    }
  }
`;
